<template>
  <div>
    <div class="anan-set-nav-conttainer mt-150">
      <div class="row">
        <div class="col-md-2 col-lg-2 col-sm-12">
          <side-bar class="d-none d-md-block" />
        </div>

        <div class="col-md-10 col-lg-10 col-sm-12">
          <div class="card">

            <div class="nav-panel anan-tabs anan-tabs-line anan-tabs-normal anan-tabs-top">
              <div class="anan-tabs__nav">
                <div
                  class="anan-tabs__nav-warp d-flex justify-content-between align-items-center flex-wrap my-1 px-2"
                  style="margin-top: 5px;"
                >

                  <div class="">
                    <h3 class="font-weight-bolder">
                      {{ $t('key-323') }}
                    </h3>

                  </div>

                  <div class="d-flex align-items-center w-lg-100">
                    <div
                      class="anan-input__inner anan-input__inner--normal pl-75 pt-25 pb-25 pr-25"
                    >
                      <input
                        v-model="search_val"
                        type="text"
                        :placeholder="$t('search')"
                        class="anan-input__input"
                        @keyup.enter="GetDate()"
                      >
                      <div class="anan-input__suffix">
                        <button
                          type="button"
                          class="anan-button anan-button--normal anan-button--primary"
                          @click="GetDate()"
                        >
                          <i class="fal fa-search" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-1">
                <button
                  class="btn btn-primary ml-1"
                  style="width: 150px;"
                  @click="$router.push({name: 'member-user-add'})"
                >
                  <i class="far fa-plus mr-25" /> {{ $t('addUser') }}
                </button>
              </div>

            </div>

            <b-overlay
              :show="showOver"
              variant="transparent"
              opacity="0.99"
              blur="5px"
              rounded="sm"
            >
              <template #overlay>
                <div class="text-center">
                  <p id="cancel-label">
                    กรุณารอสักครู่...
                  </p>
                </div>
              </template>
              <div
                class="p-2"
              >
                <b-table
                  striped
                  hover
                  responsive
                  :per-page="perPage"
                  :items="items"
                  :fields="fields"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  show-empty
                  table-class="t-width"
                >
                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <b-spinner
                        label="กำลังโหลด..."
                        variant="danger"
                      />
                    </div>
                  </template>

                  <template #empty>
                    <div class="text-center">
                      <img
                        src="@/assets/images/anan-img/empty/empty.png"
                        alt="empty"
                        width="180px"
                      >
                      <p>No Data</p>
                    </div>
                  </template>
                  <template #cell(itemnumber_text)="data">
                    <b-link
                      :to="{name: 'member-user-edit', params: {id: data.item._id}}"
                      style="font-size: 12px;"
                    >
                      <span>{{ data.item.itemnumber_text }}</span>
                    </b-link>
                    <!-- {{ data.item._id }} -->
                  </template>
                  <template #cell(action)="data">
                    <feather-icon
                      icon="Edit3Icon"
                      class="mr-1 text-primary cursor-pointer"
                      @click="EditItem(data.item)"
                    />
                    <feather-icon
                      class="text-danger cursor-pointer"
                      icon="TrashIcon"
                      @click="Deleteitem(data.item)"
                    />
                  </template>

                </b-table>

                <div class="demo-spacing-0 d-flex justify-content-end">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    first-number
                    last-number
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mb-0"
                    @change="GetDate()"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </b-overlay>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BOverlay, BPagination, BTable, BLink,
} from 'bootstrap-vue'
import SideBar from '../component/SideBar.vue'

export default {
  components: {
    BTable,
    BPagination,
    SideBar,
    // ThisHeader,
    BOverlay,
    BLink,
  },

  data() {
    return {
      isActive: 'all',
      items: [
        // {
        //   id: 1,
        //   userId: 'ANAN-001',
        //   name: 'มาโนช ลุยสวน',
        //   email: 'manoch@gmail.com',
        //   phone: '0888888888',
        // },

      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      status: 0,
      search_val: null,
      showOver: false,
    }
  },
  computed: {
    fields() {
      return [
        {
          key: 'itemnumber_text', label: this.$t('number'), sortable: false, thStyle: { width: '12%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'user_member', label: this.$t('customerCode'), sortable: false, thStyle: { width: '20%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'fullname', label: this.$t('firstName'), sortable: false, thStyle: { width: '20%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'email', label: this.$t('key-80'), sortable: false, thStyle: { width: '20%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'tel', label: this.$t('key-81'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'action', label: this.$t('manage'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
      ]
    },
  },
  mounted() {
    this.GetDate()
  },
  methods: {
    async GetDate() {
      this.showOver = true
      try {
        const params = {
          search_val: this.search_val,
          page: this.currentPage,
          perPage: this.perPage,
        }
        const { data: res } = await this.$http.get('Usermember/GetMember', { params })
        this.items = res.data
        this.totalRows = res.total
        this.showOver = false
      } catch (err) {
        this.showOver = false
        console.log(err)
      }
    },
    EditItem(data) {
      this.$router.push({ name: 'member-user-edit', params: { id: data._id } })
    },
    Deleteitem(data) {
      this.$bvModal.msgBoxConfirm('ต้องการลบข้อมูลหรือไม่ ?', {
        title: this.$t('confirm'),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      }).then(result => {
        if (result) {
          const obj = {
            ID: data._id,
          }
          this.$http.post('/Usermember/DeleteAgent', obj)
            .then(response => {
              if (response.status) {
                this.Success('ลบข้อมูลเรียบร้อย')
                this.GetDate()
              }
            })
            .catch(err => {
              console.log(err)
              this.SwalError('ลบข้อมูลไม่สำเร็จ')
            })
        }
      })
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          cancelButton: 'btn btn-outline-danger',
        },
      })
    },
  },

}
</script>

<style>
.t-width {
    min-width: 600px !important;
}

</style>
